import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get('convenio/listar?' + param2query(params), cancelToken);
};

export const getAllUsuarios = (id) => {
  return api.get('convenio/listar-usuarios/' + id);
};

export const getConvenio = (id) => {
  return api.get('convenio/' + id);
};

export const criarConvenio = (model) => {
  return api.post('convenio', model, {
    headers : {
      "Content-Type" : "multipart/form-data"
    }
  })
}

export const atualizarConvenio = (model) => {
  return api.put('convenio', model, {
    headers : {
      "Content-Type" : "multipart/form-data"
    }
  })
}

export const pesquisarUsuarioSelect = (query) => {
  return api.get('convenio/buscar-usuarios?filtro=' + query);
}

export const adicionarUsuarioConvenio = (usuarioId, convenioId) => {
  return api.post('convenio/adicionar-usuario', {
    usuarioId, convenioId,
    ativo: true, isCriar: true
  })
}

export const alterarUsuarioConvenio = (usuarioId, convenioId, ativo) => {
  return api.put('convenio/alterar-usuario', {
    usuarioId, convenioId, ativo, isCriar: false}
  )
}

export const excluirUsuarioConvenio = (usuarioId, convenioId) => {
  return api.delete('convenio/excluir-usuario?' + param2query({ usuarioId, convenioId}) 
  )
}

export const getConvenioLogo = (id) => {
  return api.get('resources/logo-convenio/' + id);
}

export default { getAll };
